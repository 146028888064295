<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Determine the potential of a concentration cell (at 298 K) that is constructed with
        solutions of
        <chemical-latex content="Pb(NO3)2" />
        at
        <number-value :value="conc1" unit="\text{M}" />
        and
        <number-value :value="conc2" unit="\text{M.}" />
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\text{E}_\text{cell}:$"
        append-text="$\text{V}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import NumberValue from '../NumberValue';

export default {
  name: 'ChemMcMPL5Q10',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    conc1() {
      return this.taskState.getValueBySymbol('conc1').content;
    },
    conc2() {
      return this.taskState.getValueBySymbol('conc2').content;
    },
  },
};
</script>
